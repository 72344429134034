<template>
  <div>
      <div class="flex flex-row justify-between items-center">
          <div class="border">
              <h1>Type de questions</h1>
          </div>
          <div>
              <select
                    type="text"
                    name="yearm"
                    class="w-full p-3 bg-white border border-gray-200 rounded shadow-sm appearance-none"
                >
                    <option value='' disabled selected>Veuillez selectionner le type de question que vous voulez insérer</option>
                    <option value="1">1 Année</option>
                    <option value="2">2 Année</option>
                    <option value="3">3 Année</option>
                    <option value="4">4 Année</option>
                    <option value="5">5 Année</option>
                    <option value="6">6 Année</option>
                    <option value="7">7 Année</option>
                </select>
          </div>
      </div>
  </div>
</template>

<script>
export default {

  data () {
     return {
        currentRoute: window.location.pathname
     }
   },

  props:{
    linkPath:Function
  },

  mounted () {
    this.linkPath(this.currentRoute)
  }
}
</script>

<style>

</style>